<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text ps-0 pt-5 mt-5">
          <h1>非凸动态</h1>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="container-lg px-4 py-5">
        <div
          v-for="item of newsToDisplay"
          :key="item.path"
          class="row px-2 py-4 border-bottom"
        >
          <div class="col-sm-12 col-lg-3 p-1">
            <router-link :to="item.path" v-if="!item.path.startsWith('http')">
              <img
                :src="item.image"
                :alt="item.title"
                class="img-fluid rounded"
              />
            </router-link>
            <a
              class="text-decoration-none title fs-3 fw-bold text-black"
              :href="item.path"
              v-else
            >
              <img
                :src="item.image"
                :alt="item.title"
                class="img-fluid rounded"
              />
            </a>
          </div>
          <div class="col-sm-12 col-lg-9 d-flex flex-column">
            <router-link
              class="text-decoration-none title fs-3 fw-bold text-black"
              :to="item.path"
              v-if="!item.path.startsWith('http')"
            >
              {{ item.title }}
            </router-link>
            <a
              class="text-decoration-none title fs-3 fw-bold text-black"
              :href="item.path"
              v-else
            >
              {{ item.title }}
            </a>
            <div class="info">
              <div class="tags">
                <a
                  v-for="tag of item.tags"
                  :key="tag"
                  href="#!"
                  class="badge me-1 bg-primary text-decoration-none link-light"
                  >{{ tag }}</a
                >
              </div>
              <div class="text-muted fst-italic pt-2">
                {{ item.date }}
              </div>
            </div>
            <div class="content mt-3">
              {{ item.abstract }}
            </div>
          </div>
        </div>

        <nav style="margin-top: 1.5rem">
          <ul class="pagination justify-content-center">
            <li
              class="page-item"
              v-for="index of Array(pages)
                .fill(0)
                .map((v, k) => k)"
              :key="index"
              :class="{ active: index == 0 }"
            >
              <a
                class="page-link"
                @click="pageItemClicked(index, $event)"
                href="#"
                >{{ index + 1 }}</a
              >
            </li>
          </ul>
        </nav>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      news: [
        {
          title: "非凸联合创始人李佐凡：将“科技赋能投资”作为自己的终身事业",
          image: require("../assets/img/news/10/01-李佐凡.jpg"),
          path: "/news10",
          tags: ["人物", "联合创始人"],
          date: "2022年6月24日",
          abstract:
            "李佐凡说，“我们希望与行业同频呼吸，愿意尝试，敢于试错，让中国自己的数智交易实现跨越式发展。”",
        },
        {
          title: "非凸科技荣获“创·在上海”国际创新创业大赛总决赛优胜企业20强",
          image: require("../assets/img/news/创在上海.jpg"),
          path: "https://mp.weixin.qq.com/s?__biz=MzA3Mzg5Njc2OA==&mid=2247488620&idx=1&sn=ac2e1413ac4f37e3e995bcd4025ac21a&chksm=9e4103c868a2c4012950e69feaef725066f2da795291826fe1f9fac198e013b77f1273bf9881&mpshare=1&scene=1&srcid=03149T9eXXU9bMSFjZphuFXM&sharer_shareinfo=8b22abb19ab65389bca481d6dac9828d&sharer_shareinfo_first=8b22abb19ab65389bca481d6dac9828d&version=4.1.20.99416&platform=mac#rd",
          tags: ["新闻", "荣誉奖项"],
          date: "2025年03月06日",
          abstract:
            "经过初赛、复赛、预决赛、总决赛多轮角逐，非凸科技从万余家科技企业中强势突围，跻身总决赛优胜企业20强榜单。",
        },
        {
          title: "非凸科技受邀参加GDC Talk，与全球开发者共探前沿技术",
          image: require("../assets/img/news/GDC.jpg"),
          path: "https://mp.weixin.qq.com/s?__biz=MzA3Mzg5Njc2OA==&mid=2247488609&idx=1&sn=579638ce666689debcac0c05a7ae787e&chksm=9e236b3d33bfaea008f9563244e03afce244b38487b60f101a76e77c17faf1372672e859f584&mpshare=1&scene=1&srcid=0314P88zbBAIHAfo1M9QM2EG&sharer_shareinfo=962784502003be0652aa3a9ce59e6ff2&sharer_shareinfo_first=962784502003be0652aa3a9ce59e6ff2&version=4.1.20.99416&platform=mac#rd",
          tags: ["新闻", "GDC"],
          date: "2025年03月05日",
          abstract:
            "受邀参与GDC Talk 开放麦活动，与人工智能产业同行分享技术实践，共话数智交易未来.",
        },
        {
          title: "非凸科技荣登毕马威中国2024金融科技企业“双50”榜单",
          image: require("../assets/img/news/101封面.jpg"),
          path: "https://mp.weixin.qq.com/s/B0LB4JSZAwaM_ildZmWJrw",
          tags: ["新闻", "荣誉奖项"],
          date: "2025年1月20日",
          abstract:
            "非凸科技凭借在金融科技领域的卓越表现和行业领先创新能力，实力入选KPMG Fintech 50。",
        },
        {
          title: "一路相伴，非凸科技助力第49届ICPC亚洲区决赛",
          image: require("../assets/img/news/100封面.jpg"),
          path: "https://mp.weixin.qq.com/s/kymb4mJ7Ps6bXPo_vt_OFw",
          tags: ["新闻", "ICPC"],
          date: "2025年1月13日",
          abstract:
            "非凸科技鼎力支持这群心怀梦想的青年才俊，激励他们勇攀科技高峰，实现创新突破。",
        },
        {
          title: "非凸科技再次斩获多项行业殊荣，感谢认可！",
          image: require("../assets/img/news/99封面.jpg"),
          path: "https://mp.weixin.qq.com/s/NUXiqSTd58_CoUQCCYQABA",
          tags: ["新闻", "荣誉奖项"],
          date: "2025年1月3日",
          abstract:
            "非凸科技自主研发实力、业务表现、技术实践、成长潜力等多维度得到了行业及协会的高度认可。",
        },
        {
          title: "非凸科技荣登脉脉2024“年度职得去雇主”榜单",
          image: require("../assets/img/news/98封面.jpg"),
          path: "https://mp.weixin.qq.com/s/9zraOcMbV7T0pc2xqHDV6g",
          tags: ["新闻", "荣誉奖项"],
          date: "2024年12月24日",
          abstract:
            "非凸科技获此殊荣，也意味着在发展前景、工作氛围、薪酬福利等方面极具竞争力，赢得了职场人的高度认可。",
        },
        {
          title: "非凸科技荣获2024“创·在上海”国际创新创业大赛优胜企业奖",
          image: require("../assets/img/news/97封面.jpg"),
          path: "https://mp.weixin.qq.com/s/ZJyT3xFEYQLRKHlV9S139w",
          tags: ["新闻", "荣誉奖项"],
          date: "2024年12月16日",
          abstract:
            "非凸科技作为晋级决赛预选企业受邀参加开营仪式，并被授予“优胜企业”奖牌与证书。",
        },
        {
          title: "非凸科技荣获2024中国人工智能行业创新力企业奖",
          image: require("../assets/img/news/96封面.jpg"),
          path: "https://mp.weixin.qq.com/s/6CvmSvWO-LK5GIkqZmB44g",
          tags: ["新闻", "荣誉奖项"],
          date: "2024年12月2日",
          abstract:
            "作为百强创新力企业受邀出席大会，并荣获“十大创新力企业”奖，在科技创新、商业模式成熟度等方面获得认可。",
        },
        {
          title: "第49届ICPC亚洲区域赛，非凸科技再次支持上海赛站",
          image: require("../assets/img/news/95封面.jpg"),
          path: "https://mp.weixin.qq.com/s/0wg1ZgmYroyhvKAVmguapg",
          tags: ["新闻", "ICPC"],
          date: "2024年11月25日",
          abstract:
            "非凸科技将持续支持全球顶尖赛事，期待一代又一代的ICPC竞赛人才能够为国家发展贡献自己的力量。",
        },
        {
          title: "携手上海证券，共同见证市场活跃背景下交易服务新趋势",
          image: require("../assets/img/news/94封面.jpg"),
          path: "https://mp.weixin.qq.com/s/ABEPo9zu-yc7kV6krx5MUw",
          tags: ["新闻", "上海证券"],
          date: "2024年11月21日",
          abstract:
            "与管理人、资方共同探讨当前市场环境下私募机构如何把握机遇、应对挑战，以及券商服务如何实现转型优化。",
        },
        {
          title: "第49届ICPC亚洲区域赛·南京站，非凸科技持续支持赛事举办",
          image: require("../assets/img/news/93封面.jpg"),
          path: "https://mp.weixin.qq.com/s/zmz5QclQ3areF0Gk98LqQg",
          tags: ["新闻", "ICPC"],
          date: "2024年11月11日",
          abstract:
            "人才是持续推动科学技术进步的关键，非凸科技将持续支持赛事举办，为国际竞赛界输送企业知识和众多资源。",
        },
        {
          title: "非凸科技助力第49届ICPC亚洲区域赛（成都）成功举办",
          image: require("../assets/img/news/92封面.jpg"),
          path: "https://mp.weixin.qq.com/s/6bIWT-VroFFPN5otHPvPDQ",
          tags: ["新闻", "ICPC"],
          date: "2024年11月4日",
          abstract:
            "期待与产学研各界专家、青年才俊一起，推动基础科学理论研究的重大突破，促进技术应用创新，共同为全球繁荣发展贡献力量。",
        },
        {
          title: "非凸科技银牌赞助GOSIM CHINA 2024，并受邀出席Rust分论坛",
          image: require("../assets/img/news/91封面.jpg"),
          path: "https://mp.weixin.qq.com/s/oEcmPOUBfX7uqDo_Tdt0MA",
          tags: ["新闻", "开源技术盛会"],
          date: "2024年10月23日",
          abstract:
            "与160+国内外行业领袖、技术专家，1000+一线开发者、开源人才，共同探讨开源生态系统的最新进展与未来趋势。",
        },
        {
          title: "非凸算法全面上线国融证券",
          image: require("../assets/img/news/90/封面.jpg"),
          path: "/news90",
          tags: ["新闻", "国融证券"],
          date: "2024年10月11日",
          abstract:
            "非凸智能底仓增强算法、拆单算法顺利上线国融证券，全面满足投资者在多种终端上的智能交易服务需求。",
        },
        {
          title: "非凸智能算法全面上线中金公司",
          image: require("../assets/img/news/89/封面.jpg"),
          path: "/news89",
          tags: ["新闻", "中金公司"],
          date: "2024年9月20日",
          abstract:
            "全新升级，平稳运行，全方位满足投资者降低交易成本、优化交易执行、提升交易效率等切实需求。",
        },
        {
          title: "非凸科技钻石赞助第四届Rust China Conf 2024",
          image: require("../assets/img/news/88封面.jpg"),
          path: "https://mp.weixin.qq.com/s/66cBmLnfGSKgcGRWmS3AIA",
          tags: ["新闻", "Rust开发者大会"],
          date: "2024年9月12日",
          abstract:
            "全力协助大会顺利开展，共同为中国Rustaceans带来一场精彩的技术交流盛宴。",
        },
        {
          title: "徐州市委书记宋乐伟一行莅临非凸科技徐州分公司调研",
          image: require("../assets/img/news/86封面.jpg"),
          path: "https://mp.weixin.qq.com/s/SjgbNdLTtizYKfsxtmC8UA",
          tags: ["新闻", "指导交流"],
          date: "2024年8月5日",
          abstract:
            "详细了解非凸数智交易产品的生态体系以及AI算力赋能的实践成果，并进行了现场指导与交流。",
        },
        {
          title: "携手广发证券，共探科技赋能下的私募发展生态圈",
          image: require("../assets/img/news/83封面.jpg"),
          path: "https://mp.weixin.qq.com/s/t4A46tuWHpI0ynxKuGqW0A",
          tags: ["新闻", "广发证券"],
          date: "2024年7月3日",
          abstract:
            "与私募管理人、资方共同探讨新形势下私募基金的高质量发展及科技赋能下券商综合服务的转型优化。",
        },
        {
          title: "非凸科技受邀参加，探讨数智交易如何助力投资者应对市场挑战",
          image: require("../assets/img/news/82/封面.jpg"),
          path: "/news82",
          tags: ["新闻", "国信证券"],
          date: "2024年6月7日",
          abstract:
            "探讨如何在复杂多变的投资环境下应对机遇与挑战，以及如何更好地为投资者提供全链路数智化交易解决方案。",
        },
        {
          title: "携手国泰君安证券，探讨交易大时代下的创新与共赢",
          image: require("../assets/img/news/81/封面.jpg"),
          path: "/news81",
          tags: ["新闻", "国泰君安证券"],
          date: "2024年5月27日",
          abstract:
            "探讨如何实现科技创新与金融服务的深度融合，以及可持续发展与生态伙伴的合作共赢。",
        },
        {
          title: "非凸科技受邀出席第二届HELLO FUTURE.新生代创新大会",
          image: require("../assets/img/news/80/封面.jpg"),
          path: "/news80",
          tags: ["新闻", "创新大会"],
          date: "2024年5月6日",
          abstract:
            "探讨了科技创新赋能投资的新趋势，展现了新生代力量在行业变革中的重要作用。",
        },
        {
          title:
            "华泰证券上海普陀区江宁路营业部携手非凸科技，共话科技赋能投资新趋势",
          image: require("../assets/img/news/79/封面.jpg"),
          path: "/news79",
          tags: ["新闻", "华泰证券"],
          date: "2024年4月28日",
          abstract:
            "探讨如何通过科技创新提升交易效率与用户体验，为投资者提供更高效、更智能的金融服务。",
        },
        {
          title: "太平洋证券携手非凸科技，共同探讨交易创新与生态合作",
          image: require("../assets/img/news/78/封面.jpg"),
          path: "/news78",
          tags: ["新闻", "太平洋证券"],
          date: "2024年4月7日",
          abstract:
            "与私募管理人、资管机构负责人共同探讨了交易创新与生态合作新路径。",
        },
        {
          title: "非凸科技跻身GDC全球创新项目TOP16，掀起AI创新热潮",
          image: require("../assets/img/news/77/封面.jpg"),
          path: "/news77",
          tags: ["新闻", "创新大赛"],
          date: "2024年3月29日",
          abstract:
            "凭借出色的产品性能、领先的技术创新以及突出的合作案例，赢得专家评审团的高度认可，成功晋级决赛。",
        },
        {
          title: "走进上交高金，探讨机器学习在金融领域的应用",
          image: require("../assets/img/news/76/封面.jpg"),
          path: "/news76",
          tags: ["新闻", "校企合作"],
          date: "2024年3月25日",
          abstract:
            "分享了机器学习在金融领域的应用，以及大模型在股票市场的迁移与影响。",
        },
        {
          title: "非凸科技携手华鑫证券，共探大模型时代投资赋能",
          image: require("../assets/img/news/75/封面.jpg"),
          path: "/news75",
          tags: ["新闻", "华鑫证券"],
          date: "2024年3月20日",
          abstract:
            "与管理人、资方共同探讨了大模型技术在投资领域的应用优势与发展前景。",
        },
        {
          title: "非凸科技荣获复旦大学管理学院“年度合作伙伴”奖",
          image: require("../assets/img/news/74/封面.jpg"),
          path: "/news74",
          tags: ["新闻", "校企合作"],
          date: "2024年1月30日",
          abstract:
            "希望通过双方的互动交流和紧密合作，碰撞出更多创新灵感，实现校企共赢发展。",
        },
        {
          title: "海通证券携手非凸科技，共谋行业未来和生态合作",
          image: require("../assets/img/news/73/封面.jpg"),
          path: "/news73",
          tags: ["新闻", "海通证券"],
          date: "2024年1月29日",
          abstract:
            "共同探讨数智交易行业发展，未来策略展望及合作方向等热点话题，为科技赋能下的数智交易领域提供了深入见解。",
        },
        {
          title: "非凸算法上线华鑫证券，携手共创智能交易新生态",
          image: require("../assets/img/news/72/封面.jpg"),
          path: "/news72",
          tags: ["新闻", "华鑫证券"],
          date: "2024年1月23日",
          abstract:
            "合作过程中，非凸科技直面需求、及时响应的态度，稳扎稳打的技术实力和工程能力，皆获得了极大认可。",
        },
        {
          title: "回馈科教，非凸科技助力第48届ICPC亚洲区决赛",
          image: require("../assets/img/news/71/封面.jpg"),
          path: "/news71",
          tags: ["新闻", "ICPC"],
          date: "2024年1月17日",
          abstract:
            "作为此次赛事的支持方之一，希望携手各方共同推动计算机科学和技术的发展。",
        },
        {
          title: "非凸科技受邀出席北京基金小镇&华鑫证券联合分享会",
          image: require("../assets/img/news/70/封面.jpg"),
          path: "/news70",
          tags: ["新闻", "华鑫证券"],
          date: "2024年1月12日",
          abstract:
            "与管理人、资方共同探讨了私募行业备案现状、合规要点、交易赋能以及未来合作机会。",
        },
        {
          title: "非凸科技受邀参加财联社圆桌论坛“AI如何赋能数智交易”",
          image: require("../assets/img/news/69/封面.jpg"),
          path: "/news69",
          tags: ["新闻", "财联社投资年会"],
          date: "2023年12月22日",
          abstract:
            "与行业专业人士就“AI技术下的行业变革、AI技术之挑战”等话题进行了深入探讨。",
        },
        {
          title: "携手海通证券，畅谈数智交易行业发展趋势及未来合作机会",
          image: require("../assets/img/news/68/封面.jpg"),
          path: "/news68",
          tags: ["新闻", "海通证券"],
          date: "2023年12月21日",
          abstract:
            "通过分享实践案例和创新思路，与会者们相互启发，为推动行业发展贡献了积极力量。",
        },
        {
          title: "非凸科技受邀出席“第十五届HED峰会”",
          image: require("../assets/img/news/67/封面.jpg"),
          path: "/news67",
          tags: ["新闻", "HED峰会"],
          date: "2023年12月20日",
          abstract:
            "与行业专家就今年投资策略市场表现、市场争议、风险控制以及未来发展等问题展开了深度交流。",
        },
        {
          title:
            "“乘长会客厅”深圳站，德邦证券携手非凸科技共探数智交易机遇与生态合作",
          image: require("../assets/img/news/66/封面.jpg"),
          path: "/news66",
          tags: ["新闻", "德邦证券"],
          date: "2023年12月18日",
          abstract:
            "未来是一个生态合作和融合创新的时代，非凸科技将聚焦技术，做好支撑，助力行业伙伴持续发展。",
        },
        {
          title: "非凸科技助力第48届ICPC亚洲区域赛（杭州）成功举办",
          image: require("../assets/img/news/65/封面.jpg"),
          path: "/news65",
          tags: ["新闻", "ICPC"],
          date: "2023年12月13日",
          abstract:
            "作为此次赛事的主要支持之一，希望与各方共同努力，为国家计算机学科人才培养做出积极贡献。",
        },
        {
          title: "非凸科技荣获第七届全球青年创新大会“金领奖”",
          image: require("../assets/img/news/64/封面.jpg"),
          path: "/news64",
          tags: ["新闻", "荣获奖项"],
          date: "2023年12月11日",
          abstract:
            "未来，非凸科技也将秉承行业初心，以最具实力的技术及产品研发能力，持续推动数智化交易未来。",
        },
        {
          title: "德邦证券联合非凸科技，开展“乘长会客厅”北京站活动",
          image: require("../assets/img/news/62/封面.jpg"),
          path: "/news62",
          tags: ["新闻", "德邦证券"],
          date: "2023年11月30日",
          abstract:
            "与机构投资者、私募管理人等行业资深人士，就算法赋能交易、策略研发效率等方面展开了深度交流。",
        },
        {
          title: "海通证券携手非凸科技，共同探讨私募生态圈的共建共赢",
          image: require("../assets/img/news/61/封面.jpg"),
          path: "/news61",
          tags: ["新闻", "海通证券"],
          date: "2023年11月21日",
          abstract:
            "与嘉宾就算法交易的未来发展以及私募生态圈的共建共赢展开了深度探讨，并对未来行业发展与合作进行了展望。",
        },
        {
          title: "RustCC分享会｜非凸科技与开发者共同探讨Rust安全进化",
          image: require("../assets/img/news/60/封面.jpg"),
          path: "/news60",
          tags: ["新闻", "技术交流"],
          date: "2023年10月23日",
          abstract:
            "从编程语言的安全性、C/C++的安全性问题、Rust的解决方案以及Rust FFI四大角度进行了详细分享。",
        },
        {
          title: "非凸科技受邀出席源创会，探讨数据技术的未来发展",
          image: require("../assets/img/news/59/封面.jpg"),
          path: "/news59",
          tags: ["新闻", "源创会"],
          date: "2023年10月10日",
          abstract:
            "与业界专家们共同探讨了数据存储、数据分析、数据挖掘等前沿技术。",
        },
        {
          title: "国投证券（原”安信证券“）携手共议数智交易行业的赋能发展",
          image: require("../assets/img/news/58/封面.jpg"),
          path: "/news58",
          tags: ["新闻", "国投证券"],
          date: "2023年10月9日",
          abstract:
            "与资方管理人就如何赋能私募基金可持续发展，给出了精彩纷呈的见解与讨论。",
        },
        {
          title: "德邦证券携手非凸科技走进“乘长会客厅”",
          image: require("../assets/img/news/56/封面.jpg"),
          path: "/news56",
          tags: ["新闻", "德邦证券"],
          date: "2023年9月7日",
          abstract:
            "与机构投资者、私募管理人等特邀嘉宾，共同探讨前沿算法，分享独特见解，促进生态伙伴共同成长。",
        },
        {
          title: "非凸联合创始人李佐凡受邀出席复旦DS&BA项目座谈会",
          image: require("../assets/img/news/55/封面.jpg"),
          path: "/news55",
          tags: ["新闻", "校企合作"],
          date: "2023年8月24日",
          abstract:
            "与学校教授、老师在生源背景、课程教学、职业发展、学生培养和企业合作方面进行深入交流。",
        },
        {
          title:
            "2023苏黎世-深圳数量金融风险夏令营，非凸联合创始人李佐凡担任特邀讲师",
          image: require("../assets/img/news/54/封面.jpg"),
          path: "/news54",
          tags: ["新闻", "校企合作"],
          date: "2023年8月11日",
          abstract:
            "带领同学们了解国内外数量金融风险行业的前沿趋势，以及学习先进技术在该领域的应用和研究。",
        },
        {
          title: "海通证券&非凸科技，探讨数智交易发展及生态合作",
          image: require("../assets/img/news/53/封面.jpg"),
          path: "/news53",
          tags: ["新闻", "海通证券"],
          date: "2023年8月9日",
          abstract:
            "双方将继续发挥各自优势，达成更多层次的深度合作，推动数智交易行业高质量发展。",
        },
        {
          title: "非凸算法正式上线国信证券，携手为数智交易赋能",
          image: require("../assets/img/news/51/封面.jpg"),
          path: "/news51",
          tags: ["新闻", "国信证券"],
          date: "2023年7月28日",
          abstract:
            "基于项目方案的高成熟度，非凸科技快速完成了部署、测试及上线，满足了券商快速展业的迫切需求。",
        },
        {
          title:
            "国投证券（原“安信证券”）携手非凸科技、泽募家，共话数智交易未来投资之道",
          image: require("../assets/img/news/49/封面.jpg"),
          path: "/news49",
          tags: ["新闻", "国投证券"],
          date: "2023年7月24日",
          abstract:
            "感谢国投证券与泽募家的支持，期待未来有更多的联动和交流，打造合作共赢生态圈。",
        },
        {
          title: "非凸科技受邀出席国元证券“北京私募会”，探讨行业发展与合作机会",
          image: require("../assets/img/news/48/封面.jpg"),
          path: "/news48",
          tags: ["新闻", "国元证券"],
          date: "2023年7月20日",
          abstract:
            "与林亿资本、龙隐投资、中量财富等多家私募机构，共同探讨私募行业发展与合作机会。",
        },
        {
          title: "非凸科技受邀出席君华汇沙龙，探讨算法交易最佳实践",
          image: require("../assets/img/news/47/封面.jpg"),
          path: "/news47",
          tags: ["新闻", "君华私募基金汇"],
          date: "2023年7月18日",
          abstract:
            "与国泰君安证券及在场嘉宾，共同探讨算法交易的诸多优势和应用实践。",
        },
        {
          title: "广发证券携手非凸科技，探讨科技赋能投资",
          image: require("../assets/img/news/45/封面.jpg"),
          path: "/news45",
          tags: ["新闻", "广发证券"],
          date: "2023年7月17日",
          abstract:
            "特别邀请到千衍私募基金，与在场嘉宾共同探讨如何为数智交易行业高质量发展蓄势赋能。",
        },
        {
          title: "非凸科技与国信证券、富国基金，共探私募机构行业生态",
          image: require("../assets/img/news/46/封面.jpg"),
          path: "/news46",
          tags: ["新闻", "国信证券"],
          date: "2023年7月14日",
          abstract:
            "在追求极致的稳定与高效的同时，非凸科技也希望通过平台交流，不断推动数智交易行业提速发展。",
        },
        {
          title: "WAIC｜非凸科技受邀参展，揭秘AI与交易",
          image: require("../assets/img/news/44/封面.jpg"),
          path: "/news44",
          tags: ["新闻", "WAIC2023"],
          date: "2023年7月13日",
          abstract:
            "大会首次引入“开源集市”多元融合创新活动，汇聚了全球技术大牛、AI大咖，展示了开源魅力，分享了技术成果。",
        },
        {
          title: "非凸科技金牌赞助“第三届中国Rust开发者大会”",
          image: require("../assets/img/news/41/封面.jpg"),
          path: "/news41",
          tags: ["新闻", "Rust开发者大会"],
          date: "2023年6月21日",
          abstract:
            "非凸科技作为金牌赞助商，全力协助大会顺利开展，共同为中国 Rustaceans带来一场技术交流盛宴。",
        },
        {
          title: "非凸科技在第五届全球金融科技创业大赛中荣获“最具创新力企业”奖",
          image: require("../assets/img/news/38/封面.jpg"),
          path: "/news38",
          tags: ["新闻", "大赛获奖"],
          date: "2023年5月17日",
          abstract:
            "作为50强企业受邀参加总决赛，获得荣誉奖项，在商业模式成熟度、市场地位、科技创新等多方面获得认可。",
        },
        {
          title: "非凸算法正式上线国联证券，赋能机构业务高质量发展",
          image: require("../assets/img/news/36/封面.jpg"),
          path: "/news36",
          tags: ["新闻", "国联证券"],
          date: "2023年5月12日",
          abstract:
            "在高性能交易、高效率风控、高质量服务等方面展开深度合作，共同为算法生态建设贡献力量。",
        },
        {
          title: "再添新伙伴！非凸算法正式上线国投证券（原”安信证券“）",
          image: require("../assets/img/news/33/封面.jpg"),
          path: "/news33",
          tags: ["新闻", "国投证券"],
          date: "2023年4月24日",
          abstract:
            "助力国投证券不断提升展业能力、丰富算法策略以及完善服务体系等，从而为机构客户提供差异化、多样化的算法服务。",
        },
        {
          title: "非凸科技受邀出席东北证券京津冀私募圈俱乐部线下交流会",
          image: require("../assets/img/news/31/封面.jpg"),
          path: "/news31",
          tags: ["新闻", "东北证券"],
          date: "2023年3月30日",
          abstract:
            "交流会上，非凸科技深度分享了非凸算法及其应用实践，并与在场嘉宾共同探讨了行业发展新趋势及算法合作新模式。",
        },
        {
          title: "非凸科技参加第五届全球金融科技创业大赛上海赛区总决赛",
          image: require("../assets/img/news/30/封面.jpg"),
          path: "/news30",
          tags: ["新闻", "创业大赛"],
          date: "2023年3月23日",
          abstract:
            "非凸科技经过层层选拔，凭借其创新性和优异性，在参赛的众多项目里脱颖而出并参与此次上海赛区的线下路演。",
        },
        {
          title: "高新赋能｜非凸科技荣获国家级“高新技术企业”认定",
          image: require("../assets/img/news/29/封面.jpg"),
          path: "/news29",
          tags: ["新闻", "高新技术企业"],
          date: "2023年3月17日",
          abstract:
            "此项荣誉是国家对非凸科技在技术创新、产品研发、服务能力等方面的高度认可，也是最佳激励。",
        },
        {
          title: "海通证券携手非凸科技，开展算法推广研讨会",
          image: require("../assets/img/news/27/封面.jpg"),
          path: "/news27",
          tags: ["新闻", "研讨会"],
          date: "2023年1月12日",
          abstract:
            "以客户需求为核心，发挥各自资源、技术、服务等优势，协同合作，持续创新，推动算法生态建设。",
        },
        {
          title: "新伙伴！非凸算法即将上线海通证券",
          image: require("../assets/img/news/26/封面.jpg"),
          path: "/news26",
          tags: ["新闻", "海通证券"],
          date: "2022年12月23日",
          abstract:
            "近日，非凸科技与海通证券达成总对总合作，发挥各自优势，共建数智交易行业生态。",
        },
        {
          title: "实力认可！非凸算法正式上线国泰君安证券",
          image: require("../assets/img/news/25/封面.jpg"),
          path: "/news25",
          tags: ["新闻", "国泰君安证券"],
          date: "2022年12月16日",
          abstract:
            "近日，非凸智能算法上线国泰君安证券，凭借高绩效、稳运行、强技术获得券商及管理人的高度认可。",
        },
        // {
        //   title: "非凸开源项目：高速时间戳fttime",
        //   image: require("../assets/img/news/24/封面.jpg"),
        //   path: "/news24",
        //   tags: ["新闻","开源项目"],
        //   date: "2022年12月7日",
        //   abstract: "fttime可以将单次获取时间戳的延时降低到1-2ns，对比标准库缩短80+%。"
        // },
        {
          title: "非凸算法上线银河证券，助力机构交易业务",
          image: require("../assets/img/news/22/封面.jpg"),
          path: "/news22",
          tags: ["新闻", "银河证券"],
          date: "2022年11月30日",
          abstract:
            "非凸智能算法上线银河证券启明iTrade算法中心平台，助力机构提升展业能力、丰富算法策略，完善服务体系等。",
        },
        {
          title: "携手共赢！非凸科技与多家头部系统供应商合作",
          image: require("../assets/img/news/21/封面.jpg"),
          path: "/news21",
          tags: ["新闻", "系统供应商"],
          date: "2022年11月23日",
          abstract:
            "近日，多家头部系统供应商向非凸科技发出邀约合作，聚焦数智交易服务生态圈展开深入交流。",
        },
        {
          title: "非凸最新算法全面上线中泰证券XTP",
          image: require("../assets/img/news/20/封面.jpg"),
          path: "/news20",
          tags: ["新闻", "中泰证券"],
          date: "2022年11月14日",
          abstract:
            "近日，非凸智能算法最新版本正式上线中泰证券 XTP，助力算法生态圈快速发展。",
        },
        {
          title: "复旦大学 DS&BA项目启动，非凸联合创始人李佐凡担任硕士职业导师",
          image: require("../assets/img/news/19/封面.jpg"),
          path: "/news19",
          tags: ["新闻", "校企合作"],
          date: "2022年11月9日",
          abstract:
            "近日，非凸科技与复旦大学管理学院展开“产学研教”深入合作，希望通过DS&BA项目提高学生的实践能力和应用能力。",
        },
        {
          title: "非凸科技受邀出席中泰证券第四届XTP开发者大会",
          image: require("../assets/img/news/18/封面.jpg"),
          path: "/news18",
          tags: ["新闻", "中泰XTP大会"],
          date: "2022年12月1日",
          abstract:
            "11月6日，非凸科技首席架构师乔丹在会上分享了“Rust在算法交易中的实际应用与积极效应”，满满技术干货！",
        },
        {
          title: "开源！非凸 Rust 高性能日志库ftlog",
          image: require("../assets/img/news/17/封面.jpg"),
          path: "/news17",
          tags: ["新闻", "开源项目"],
          date: "2022年11月1日",
          abstract:
            "ftlog支持“受限写入”以及“时间切分”等功能，具备显著的性能优势。",
        },
        {
          title: "非凸CTO李佐凡对话复旦徐云杰教授",
          image: require("../assets/img/news/16/封面.jpg"),
          path: "/news16",
          tags: ["新闻", "校企合作"],
          date: "2022年10月12日",
          abstract:
            "交流会上，双方主要在学生培养、项目课题、实践创新、实习细则等方面展开了深入交流。",
        },
        {
          title: "抱自然，聚力前行！迎接变化，持续迭代！",
          image: require("../assets/img/news/15/封面.jpg"),
          path: "/news15",
          tags: ["新闻", "团建活动"],
          date: "2022年10月9日",
          abstract: "两天一晚的“东方绿舟”团建活动，为非凸人再次启航蓄满能量。",
        },
        {
          title: "非凸智能算法即将上线中国银河证券",
          image: require("../assets/img/news/14/01-封面.jpg"),
          path: "/news14",
          tags: ["新闻", "银河证券"],
          date: "2022年9月16日",
          abstract:
            "致力于为更多的数智交易类客户提供智能拆单服务，有效增厚超额收益。",
        },
        {
          title: "数据安全，系统稳定，非凸运营团队做客户背后坚定的力量",
          image: require("../assets/img/news/12/01-封面.jpg"),
          path: "/news12",
          tags: ["新闻", "团建活动"],
          date: "2022年9月3日",
          abstract:
            "9月3日，非凸运营团队在今年疫情后迎来首次团建活动，希望营造团结协作、奋发向上的团队氛围。",
        },
        {
          title: "校企融合，打造金融科技应用人才高地",
          image: require("../assets/img/news/09/校企合作-封面.jpg"),
          path: "/news09",
          tags: ["新闻", "校企合作"],
          date: "2022年5月29日",
          abstract:
            "非凸科技与江苏师范大学科文学院经济学院签署校企战略合作协议，双方将在人才培养、科研合作、技术创新等方面展开深度合作。",
        },
        {
          title: "非凸科技与东北证券达成战略合作",
          image: require("../assets/img/news/08/战略合作封面.jpg"),
          path: "/news08",
          tags: ["新闻", "东北证券"],
          date: "2022年5月12日",
          abstract:
            "非凸科技作为技术支持战略合作单位，联合东北证券，打造覆盖机构全生命周期的服务生态圈。",
        },
        {
          title: "非凸科技&CSDN，招聘直播宣讲会圆满落幕",
          image: require("../assets/img/news/05/cover.jpg"),
          path: "/news05",
          tags: ["招聘", "直播宣讲"],
          date: "2022年2月11日",
          abstract:
            "非凸科技荣获 CSDN 颁发的“人才合作伙伴”证书，成为 CSDN 线上工程师岗位招聘年度合作伙伴。",
        },
        {
          title: "非凸算法助阵 X-Club XTP 开发者大会",
          image: require("../assets/img/news/04/cover.jpg"),
          path: "/news04",
          tags: ["新闻", "中泰XTP大会"],
          date: "2020年12月18日",
          abstract:
            "12月18日，中泰证券主办的“X-Club XTP 开发者大会”在上海隆重举行，非凸算法助阵。",
        },
        {
          title: "非凸科技受邀出席第十届 HED 峰会",
          image: require("../assets/img/news/03/cover.jpg"),
          path: "/news03",
          tags: ["新闻", "第十届HED峰会"],
          date: "2020年12月8日",
          abstract:
            "12月8日，财视中国联合中泰证券举办的“第十届 HED 峰会”在深圳隆重举行，非凸科技受邀出席。",
        },
        {
          title: "非凸智能算法正式上线中泰 XTP 系统",
          image: require("../assets/img/news/02/cover.jpg"),
          path: "/news02",
          tags: ["新闻", "中泰证券"],
          date: "2020年9月14日",
          abstract:
            "非凸智能算法于2020年9月14日作为首家智能算法服务商正式上线中泰 XTP 系统。",
        },
        {
          title: "非凸科技与中泰证券达成合作",
          image: require("../assets/img/news/01/cover.jpg"),
          path: "/news01",
          tags: ["重磅", "中泰证券"],
          date: "2020年7月29日",
          abstract:
            "近日，上海非凸智能科技有限公司（简称“非凸科技”）与中泰证券股份有限公司（简称“中泰证券”）达成合作。",
        },
      ],
      newsToDisplay: [],
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.news.length / this.perPage);
    },
  },
  mounted() {
    this.newsToDisplay = this.news.slice(0, this.perPage);
  },
  methods: {
    pageItemClicked(index, event) {
      this.newsToDisplay = this.news.slice(
        index * this.perPage,
        (index + 1) * this.perPage
      );
      document.querySelectorAll(".page-item").forEach((v) => {
        v.classList.remove("active");
      });
      console.log(event.currentTarget);
      event.currentTarget.parentElement.classList.add("active");
    },
  },
};
</script>

<style scoped>
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/about/banner1.jpeg");
  background-position-y: -30%;
}
.page-item {
  cursor: pointer;
}
</style>
